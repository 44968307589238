@import '../../../../styles/customMediaQueries.css';
.root {
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 3rem;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  color: var(--colorBlack);
}
.heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 3rem;
  border-bottom: 2px solid #000000;
  padding-bottom: 1rem;
}
.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
    .contentItem:nth-of-type(1) {
      order: 1;
    }
    .contentItem:nth-of-type(2) {
      order: 3;
    }
    .contentItem:nth-of-type(3) {
      order: 2;
    }
    .contentItem:nth-of-type(4) {
      order: 4;
    }
  }
}
.contentItem {
  padding-right: 0.5rem;
  /* padding-bottom: 1rem; */
}
