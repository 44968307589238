@import '../../../../styles/customMediaQueries.css';
.root {
  position: relative;
}
.wrapper {
  position: relative;
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  padding: 0rem;
  padding-left: 0rem;
  @media (--viewportSmall) {
    /* min-height: calc(70vh - var(--topbarHeight)); */
  }
  @media (--viewportMedium) {
    padding-left: 0rem;
    /* height: calc(100vh - var(--topbarHeight)); */
  }
}
.container {
  /* max-width: 1200px; */
  position: absolute;
  padding: 1rem;
  width: 90%;
  height: 40%;
  text-align: left;
  margin: auto;
  left: 5%;
  @media (--viewportSmall) {
    height: 80%;
    text-align: left;
    left: 5%;
  }
  @media (--viewportMedium) {
    height: 50%;
    left: 10%;
    text-align: left;
  }
  @media (--viewportLarge) {
    height: 100%;
    left: 10%;
    text-align: left;
  }
}
.wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: rgba(0, 0, 0, 0.2);
}

.searchButton {
  composes: buttonDefault from global;
  max-width: 200px;
  z-index: 1;

  &:hover {
    text-decoration: none;
  }
}
.HeroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* max-width: 800px; */
  color: var(--colorWhite);
  @media (min-width: 548px) and (max-width: 670px) {
    height: 80%;
  }
}
.heroMainTitle {
  font-size: 25px;
  line-height: 1;
  @media (--viewportSmall) {
    font-size: 30px;
  }
  @media (--viewportMedium) {
    font-size: 55px;
    line-height: 1.1;
  }
  @media (--viewportLarge) {
    font-size: 70px;
    line-height: 1.1;
  }
}
.heroSubTitle {
  margin-top: 0;
  padding-top: 0;
}
.image {
  aspect-ratio: 12/ 9;
  object-fit: cover;

  @media (--viewportMedium) {
    aspect-ratio: 14/ 9;
  }
  @media (--viewportLarge) {
    aspect-ratio: 17/ 9;
  }
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}
.second {
  position: relative;
}

.buttonContainer{
  display:flex;
  flex-direction:column ;
  gap:10px;
  @media (--viewportMedium) {
  flex-direction:row ;


  }
}