.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* max-width: 99vw; */
  overflow: hidden;
}

.sectionWrapper {
  width: 100%;
  margin-bottom: 40px;
}

.sectionTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
