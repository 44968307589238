@import '../../../../styles/customMediaQueries.css';
.root {
  background-color: var(--colorGrey800);
  width: 100%;
}
.container {
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 1rem;
}
.heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  /* padding-bottom: 1rem; */
  color: var(--colorWhite);
}
.subHeading {
  /* font-size: 1.5rem; */
  font-weight: 400;
  margin-bottom: 1rem;
  /* padding-bottom: 1rem; */
  color: var(--colorWhite);
}
.section {
  border: 1px solid var(--colorWhite);
  padding: 2rem;
  color: var(--colorWhite);
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.first {
  width: 100%;
  @media (--viewportMedium) {
    width: 55%;
  }
}
.second {
  width: 100%;
  border: 1px solid var(--colorWhite);
  /* padding: 1rem; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  scroll-snap-type: x mandatory;
  overflow-x: hidden;
  scroll-behavior: smooth;
  @media (--viewportMedium) {
    width: 35%;
  }
}
.slideContent {
  min-width: 100%;
  padding: 1rem;
  /* margin-right: 2rem; */
}
.image {
  aspect-ratio: 8/7;
  /* object-fit: contain; */
}
.prevButton,
.nextButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--colorWhite);
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem;
  @media (--viewportMedium) {
    font-size: 3rem;
  }
}
.hide {
  background-color: transparent;
  border: none;
  outline: none;
  opacity: 0;
  cursor: pointer;
  color: var(--colorWhite);
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem;
  @media (--viewportMedium) {
    font-size: 3rem;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colorWhite);
}
.button {
  border: 1px solid var(--colorWhite);
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  color: var(--colorWhite);
  /* margin-top: 1rem; */
}
.cardHeading {
  font-size: 22px;
  line-height: 1.1;
  text-align: center;
}
